function filterType(groupName, tasks, arr: any) {
  tasks.forEach(cur => {
    if (cur?.groupName === groupName) {
      arr.push(cur)
    }
    if (cur?.tasks && cur.tasks.length > 0) {
      filterType(groupName, cur.tasks, arr)
    }
  })
}

/**
 * 根据dsl名称生成节点名称
 * @param groupName 节点分组
 * @param tasks 当前工作流所有节点
 * @param name dsl的名称
 * @returns 名称
 */
export function genderNodeName(groupName: string, tasks: any[] = []) {
  const arr: any = []
  filterType(groupName, tasks, arr)
  if (arr && arr.length > 0) {
    try {
      const numArr = arr.map((cur, index: number) => {
        // 取名称上的数组下标
        const item = cur.name.split('_')
        if (item.length > 1) {
          return Number(item[1]) || 1
        }
        return index
      })
      const maxNum = Math.max.apply(null, numArr)

      return `${groupName?.replaceAll('/', '')}_${Number(maxNum) + 1}`
    } catch (error) {
      return `${groupName?.replaceAll('/', '')}_${arr.length}`
    }
  }
  return `${groupName?.replaceAll('/', '')}_1`
}

/**
 * @description 函数重新排序
 * @author Yun.kou
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// ----------------------------------------------------------------------
// sonar 安全问题：Origins should be verified during cross-origin communications
// 目前需要 postMessage 通信的地址有：
// 测试环境：https://unity-dev.ekuaibao.net/
// release环境：https://unity-test.ekuaibao.net/
// 正式环境：https://unity.ekuaibao.com/
// QA环境：https://unity-pre.ekuaibao.com/

export const postMessage = (data: any) => {
  window?.parent?.postMessage(data, 'https://unity-dev.ekuaibao.net/')
  window?.parent?.postMessage(data, 'https://unity-test.ekuaibao.net/')
  window?.parent?.postMessage(data, 'https://unity.ekuaibao.com/')
  window?.parent?.postMessage(data, 'https://unity-pre.ekuaibao.com/')
}

// ----------------------------------------------------------------------
//  将object 多层级格式改转换成用keyPath为一层级的格式
export const flattenObject = (obj: Object, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, flattenObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})

export function findTaskByName(tasks, targetName) {
  for (let task of tasks) {
    if (task.name === targetName) {
      return task
    }
    if (task.tasks) {
      const foundTask = findTaskByName(task.tasks, targetName)
      if (foundTask) {
        return foundTask
      }
    }
  }
  return null
}

export function getTasksByTaskIndex(tasks, taskIndex) {
  let taskList = tasks
  return taskIndex.reduce((prev, next) => {
    prev.push(taskList[next])
    taskList = taskList[next]?.tasks ?? []
    return prev
  }, [])
}

export function getTaskNameByIndices(data, _indices, name = 'name') {
  const indices = _indices.slice()
  // 递归终止条件：当indices为空时，返回当前data的name属性
  if (indices.length === 0) {
    return data[name]
  }

  // 取出indices的第一个元素，并从indices中移除它
  const index = indices.shift()

  // 检查当前索引是否在data的范围内
  if (index < 0 || index >= data.length) {
    return 'Index out of bounds' // 索引超出范围
  }

  // 获取当前索引对应的元素
  const currentItem = data[index]

  // 如果当前元素有tasks属性，且indices还有剩余元素，则递归调用当前函数
  if ('tasks' in currentItem && indices.length > 0) {
    return getTaskNameByIndices(currentItem.tasks, indices)
  }

  // 如果当前元素没有tasks属性，或者indices没有剩余元素，则返回当前元素的name属性
  return currentItem[name]
}

export function deepCompare(a, b) {
  const aProps = Object.keys(a)

  for (let prop of aProps) {
    const aProp = a[prop]
    const bProp = b[prop]

    if (Array.isArray(aProp) && Array.isArray(bProp)) {
      if (aProp.length !== bProp.length || !aProp.every((val, index) => deepCompare(val, bProp[index]))) {
        return false
      }
    } else if (typeof aProp === 'object' && typeof bProp === 'object') {
      if (!deepCompare(aProp, bProp)) {
        return false
      }
    } else if (aProp !== bProp) {
      return false
    }
  }

  return true
}
